import * as Variables from "./variables";
import * as Enums from "./_enumerations";
import { modal } from "./modal";
declare function Swiper(...args: any[]): void;

class Home {
    private static _instance: Home;
    private static _context: HTMLElement = document.getElementById("home");
    private _swipers: Array<any> = [];
    private _mobileSwipers: Array<any> = [];

    private constructor() {
        Home._context.querySelectorAll(".swiper-container.swiper-all").forEach(swiper => {
            this._swipers.push(new (Swiper as any)(swiper, this.GetSwiperConfiguration(swiper as HTMLElement)));
        });

        const link = Home._context.querySelector(".link-checkdeliverydate");
        if (link)
            modal.Show("modal-checkdeliverydate");

        const screen_Sm_Lower = Variables.screen(Enums.Screen.sm, Enums.ScreenBoundary.lower);
        screen_Sm_Lower.addListener((e) => this.Screen_Sm_Lower_Change(e.matches));
        this.Screen_Sm_Lower_Change(screen_Sm_Lower.matches);

        const groupProd = Home._context.querySelector(".home-groupprod");
        if (groupProd) {
            groupProd.querySelectorAll(".swiper-slide").forEach((slide: HTMLElement) => {
                slide.addEventListener("click", () => (window as any).swogoGA.AddToCart({ productReference: slide.dataset.productReference, itemReference: slide.dataset.itemReference, quantity: 1 }));
            });
            groupProd.querySelector(".group .btn").addEventListener("click", () => {
                groupProd.querySelectorAll(".swiper-slide").forEach((slide: HTMLElement) => {
                    (window as any).swogoGA.AddToCart({ productReference: slide.dataset.productReference, itemReference: slide.dataset.itemReference, quantity: 1 });
                });
            });
        }

        window.addEventListener("load", () => {
            const slider = Home._context.querySelector("#home-slider");

            if (slider) {
                const navButtons = slider.querySelectorAll(".swiper-navigation-button");

                if (navButtons) {
                    slider.addEventListener("mouseover", () => { navButtons.forEach(button => { button.classList.remove("hidden"); }); });
                    slider.addEventListener("mouseout", () => { navButtons.forEach(button => { button.classList.add("hidden"); }); });
                }

                setInterval(() => {
                    const activeSlide = slider.querySelector(".swiper-slide-active img") as HTMLImageElement;

                    if (activeSlide) {
                        let rgb = this.getAverageRGB(activeSlide);
                        let lightness = this.RGBToHSL(rgb.r, rgb.g, rgb.b);

                        let navBullets = slider.querySelectorAll(".swiper-pagination-bullet");
                        let activeBullet = slider.querySelector(".swiper-pagination-bullet-active") as HTMLElement;

                        if (navBullets && activeBullet) {
                            navBullets.forEach(bullet => { (bullet as HTMLElement).style.backgroundColor = "#C4C4C4"; });

                            if (lightness > 60) {
                                activeBullet.style.backgroundColor = "#E10600";
                            } else {
                                activeBullet.style.backgroundColor = "white";
                            }
                        }
                    }
                }, 50);
            }
        });
    }


    private Screen_Sm_Lower_Change(matches: boolean) {
        if (matches) {
            while (this._mobileSwipers.length > 0)
                this._mobileSwipers.shift().destroy();
        } else {
            if (typeof Swiper === "function") {
                Home._context.querySelectorAll(".swiper-container.swiper-mobile").forEach(swiper => {
                    this._mobileSwipers.push(new (Swiper as any)(swiper, this.GetSwiperConfiguration(swiper as HTMLElement)));
                });
            }
        }
    }

    private GetSwiperConfiguration(swiper: HTMLElement) {
        return {
            spaceBetween: 15,
            preloadImages: false,
            watchOverflow: true,
            lazy: true,
            navigation: {
                nextEl: swiper.querySelector(".swiper-button-next"),
                prevEl: swiper.querySelector(".swiper-button-prev"),
            },
            pagination: {
                el: swiper.querySelector(".swiper-pagination"),
                clickable: true
            },
            autoplay: {
                delay: 7000,
            },
        };
    }

    private getAverageRGB(imgEl: HTMLImageElement) {
        let blockSize = 5, // only visit every 5 pixels
            defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
            canvas = document.createElement("canvas"),
            context = canvas.getContext && canvas.getContext("2d"),
            data, width, height,
            i = -4,
            length,
            rgb = { r: 0, g: 0, b: 0 },
            count = 0;

        if (!context) {
            return defaultRGB;
        }

        height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
        width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

        context.drawImage(imgEl, 0, 0);

        try {
            data = context.getImageData(0, 0, width, height);
        } catch (e) {
            console.log("Security error, image cannot be read as it is on a different domain.");
            return defaultRGB;
        }

        length = data.data.length;

        while ((i += blockSize * 4) < length) {
            ++count;
            rgb.r += data.data[i];
            rgb.g += data.data[i + 1];
            rgb.b += data.data[i + 2];
        }

        // ~~ used to floor values
        rgb.r = ~~(rgb.r / count);
        rgb.g = ~~(rgb.g / count);
        rgb.b = ~~(rgb.b / count);

        return rgb;
    }

    private RGBToHSL(r: number, g: number, b: number) {
        // Make r, g, and b fractions of 1
        r /= 255;
        g /= 255;
        b /= 255;

        // Find greatest and smallest channel values
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        // Calculate hue
        // No difference
        if (delta === 0)
            h = 0;
        // Red is max
        else if (cmax === r)
            h = ((g - b) / delta) % 6;
        // Green is max
        else if (cmax === g)
            h = (b - r) / delta + 2;
        // Blue is max
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        // Make negative hues positive behind 360°
        if (h < 0)
            h += 360;

        // Calculate lightness
        l = (cmax + cmin) / 2;

        // Calculate saturation
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

        // Multiply l and s by 100
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        //return "hsl(" + h + "," + s + "%," + l + "%)";
        return l;
    }


    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}
export const home = Home.Instance;